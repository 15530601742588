import { fetchWithTimeout, getLocalStorageJson, getUser } from '../util';
import moment from 'moment';
import { getAndReturnDataWithAuth, postAndReturnData } from './helperFunctions';
import { sortEvents } from '../components/calendar/EventFunctions';
let localStorageKey = document.location.hostname + ':state-history';

export const getUserFirstnameHooks = (setIsAuthenticated, setUserFirstname) => {
    // Check in local storage if user is authenticated
    const dataFromLocalStorage = getLocalStorageJson(localStorageKey, {});
    const userObj = dataFromLocalStorage.currentUser;
    if (userObj) {
        if (Object.keys(userObj).length > 0 && userObj.userFirstname) {
            setIsAuthenticated(true);
            if (setUserFirstname) {
                setUserFirstname(userObj.userFirstname);
            }
        } else {
            setIsAuthenticated(false);
        }
    } else {
        setIsAuthenticated(false);
    }
};

export const getUserId = () => {
    const dataFromLocalStorage = getLocalStorageJson(localStorageKey, {});
    const userObj = dataFromLocalStorage.currentUser;
    if (userObj) {
        if (Object.keys(userObj).length > 0 && userObj.userFirstname) {
            return userObj.userId;
        } else {
            return '';
        }
    } else {
        return '';
    }
};

// Only intro meeting registration data
export const getRegistrationData = () => {
    const dataFromLocalStorage = getLocalStorageJson(localStorageKey, {});
    return dataFromLocalStorage.registrationData;
};

export const getLatestRegData = (context) => {
    const regData = getRegistrationData();
    if (regData) {
        if (removeExpiredData(regData, context)) {
            return getRegistrationData();
        } else {
            return regData;
        }
    } else {
        return {};
    }
};

export const getCookie = (name) => {
    const cookieName = name + '=';
    let decodedCookie = decodeURIComponent(document.cookie);
    let cookieArray = decodedCookie.split(';');
    for (let i = 0; i < cookieArray.length; i++) {
        let cookie = cookieArray[i];
        while (cookie.charAt(0) === ' ') {
            cookie = cookie.substring(1);
        }
        if (cookie.indexOf(cookieName) === 0) {
            return cookie.substring(cookieName.length, cookie.length);
        }
    }
    return '';
};

// export const checkAndGetCookie = (name) => {
//   // If user is trying to get the 'token', we should check if it is still valid
//   // if token expired, we should log the user out.
//   if (name === "token") {
//     // Get tokenExpires value
//     const tokenExpires = getCookie("tokenExpires");
//     const now = moment().toISOString();
//     // If now is later when the token expires, we should log the user out.

//     if (moment(now).isAfter(moment(tokenExpires))) {
//       return (window.location.href = "/sessionen-udloebet");
//     } else {
//       return getCookie(name);
//     }
//   } else {
//     return getCookie(name);
//   }
// };

export const getAuthentication = (setIsAuthenticated) => {
    const dataFromLocalStorage = getLocalStorageJson(localStorageKey, {});
    const userObj = dataFromLocalStorage.currentUser;
    if (userObj) {
        if (userObj.userFirstname && userObj.userFirstname !== '') {
            setIsAuthenticated(true);
        } else {
            setIsAuthenticated(false);
        }
    }
};

// Checks if data is expired in local storage
const isExpired = (dataFromStorage) => {
    if (dataFromStorage.expiry) {
        const now = new Date();
        if (now.getTime() > dataFromStorage.expiry) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
};

// Remove data from storage if expired
export const removeExpiredData = (dataFromStorage, context) => {
    if (dataFromStorage) {
        if (isExpired(dataFromStorage)) {
            context.saveRegistrationData({});
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
};

export const returnAuthenticated = () => {
    const dataFromLocalStorage = getLocalStorageJson(localStorageKey, {});
    const userObj = dataFromLocalStorage.currentUser;
    if (!userObj || !userObj.userFirstname || userObj.userFirstname === '') {
        return false;
    } else {
        return true;
    }
};

export const getUserName = () => {
    const dataFromLocalStorage = getLocalStorageJson(localStorageKey, {});
    const userObj = dataFromLocalStorage.currentUser;
    if (userObj) {
        if (Object.keys(userObj).length > 0 && userObj.userFirstname) {
            return userObj.userFirstname;
        } else {
            return '';
        }
    } else {
        return '';
    }
};

export const getUserEmail = () => {
    const dataFromLocalStorage = getLocalStorageJson(localStorageKey, {});
    const userObj = dataFromLocalStorage.currentUser;
    if (userObj) {
        if (Object.keys(userObj).length > 0 && userObj.userEmail) {
            return userObj.userEmail;
        }
    }
};

export const getSubscriber = () => {
    const dataFromLocalStorage = getLocalStorageJson(localStorageKey, {});
    const userObj = dataFromLocalStorage.currentUser;
    if (userObj) {
        if (Object.keys(userObj).length > 0 && userObj.subscriber) {
            return userObj.subscriber;
        }
    }
};

export const getRole = () => {
    const dataFromLocalStorage = getLocalStorageJson(localStorageKey, {});
    const userObj = dataFromLocalStorage.currentUser;
    if (userObj) {
        if (Object.keys(userObj).length > 0 && userObj.role) {
            return userObj.role;
        }
    }
};

// Changes userObject in context - which triggers Effects
export const getUserData = (context) => {
    // Check in local storage if user is authenticated
    const dataFromLocalStorage = getLocalStorageJson(localStorageKey, {});
    const userObj = dataFromLocalStorage.currentUser;
    // eslint-disable-next-line
    if (userObj) {
        if (
            Object.keys(userObj).length > 0 &&
            userObj.userFirstname &&
            userObj.userEmail &&
            userObj.userId
        ) {
            // Update context too
            context.saveUser({
                userFirstname: userObj.userFirstname,
                userEmail: userObj.userEmail,
                userId: userObj.userId,
                emailVerifiedAt: userObj.emailVerifiedAt,
                phoneVerifiedAt: userObj.phoneVerifiedAt,
                subscriber: userObj.subscriber,
                role: userObj.role,
                token: userObj.token,
                tokenExpires: userObj.tokenExpires,
                lastDayOfSubscription: userObj.lastDayOfSubscription,
                subscriptionDueDate: userObj.subscriptionDueDate,
                inLastMonthOfSubscription: userObj.inLastMonthOfSubscription,
                isProfilePublic: userObj.isProfilePublic,
                lastAcceptedTerms: userObj.lastAcceptedTerms
            });
        } else {
            // Remove userObject from context if there is any
            context.saveUser({ userFirstname: '', userId: '', userEmail: '' });
        }
    } else {
        // Remove userObject from context if there is any
        context.saveUser({ userFirstname: '', userId: '', userEmail: '' });
    }
};

export const getCurrentUserInfo = () => {
    const dataFromLocalStorage = getLocalStorageJson(localStorageKey, {});
    return dataFromLocalStorage.currentUser;
};

export const getEventIds = () => {
    const dataFromLocalStorage = getLocalStorageJson(localStorageKey, {});
    return dataFromLocalStorage.events;
};

export const getEventIdFromStorage = () => {
    const dataFromLocalStorage = getLocalStorageJson(localStorageKey, {});
    return dataFromLocalStorage.eventId;
};

export const isSubscriber = () => {
    const dataFromLocalStorage = getLocalStorageJson(localStorageKey, {});
    const userObj = dataFromLocalStorage.currentUser;
    if (userObj) {
        if (userObj.subscriber) {
            return true;
        }
    }
};

export const hasPublicProfile = () => {
    const dataFromLocalStorage = getLocalStorageJson(localStorageKey, {});
    const userObj = dataFromLocalStorage.currentUser;
    if (userObj) {
        if (userObj.isProfilePublic) {
            return true;
        }
    }
};

export const isInLastMonthOfSubscription = () => {
    const dataFromLocalStorage = getLocalStorageJson(localStorageKey, {});
    const userObj = dataFromLocalStorage.currentUser;
    if (userObj) {
        if (userObj.inLastMonthOfSubscription) {
            return true;
        }
    }
};

export const getOrder = () => {
    const dataFromLocalStorage = getLocalStorageJson(localStorageKey, {});
    return dataFromLocalStorage.order;
};

export const getControlPoints = () => {
    const dataFromLocalStorage = getLocalStorageJson(localStorageKey, {});
    return dataFromLocalStorage.controlPoints;
};

export const getIsNewUser = () => {
    const dataFromLocalStorage = getLocalStorageJson(localStorageKey, {});
    return dataFromLocalStorage.isNewUser;
};

export const getToken = () => {
    const dataFromLocalStorage = getLocalStorageJson(localStorageKey, {});
    const userObj = dataFromLocalStorage.currentUser;
    if (userObj) {
        if (userObj.token && userObj.tokenExpires) {
            if (isTokenValid()) {
                return userObj.token;
            } else {
                window.location.href = '/sessionen-udloebet';
            }
        }
    }
};

export const getTokenExpires = () => {
    const dataFromLocalStorage = getLocalStorageJson(localStorageKey, {});
    const userObj = dataFromLocalStorage.currentUser;
    if (userObj) {
        if (userObj.tokenExpires) {
            return userObj.tokenExpires;
        }
    }
};

export const isTokenValid = () => {
    const dataFromLocalStorage = getLocalStorageJson(localStorageKey, {});
    const userObj = dataFromLocalStorage.currentUser;
    const token = userObj.token;
    const tokenExpires = userObj.tokenExpires;
    const now = moment();
    if (token && tokenExpires) {
        if (moment(now).isBefore(moment(tokenExpires))) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
};

export const getOnlySignup = () => {
    const dataFromLocalStorage = getLocalStorageJson(localStorageKey, {});
    return dataFromLocalStorage.onlySignup;
};

export const getIsFullProgressBar = () => {
    const dataFromLocalStorage = getLocalStorageJson(localStorageKey, {});
    return dataFromLocalStorage.isFullProgressBar;
};

export const getProperRoomId = (chosenRoom) => {
    switch (Number(chosenRoom)) {
        case 1: // location 1 has id 1
            return 1;
        case 2: // location 2 has id 5
            return 5;
        case 3: // location 3 has id 9
            return 9;
        case 4: // location 4 has id 10
            return 10;
        case 5: // location 5 has id 12
            return 12;
        case 6: // location 6 has id 13
            return 13;
        case 7: // location 7 has id 14
            return 14;
        case 8: // location 8 has id 16
            return 16;
        case 9: // location 9 has id 26
            return 26;

        default:
            return 1;
    }
};

// From RoomId we are able to return the location name
export const getLocationNameFromRoomId = (roomId) => {
    switch (Number(roomId)) {
        case 1: // id 1 is lokale 1
            return 1;
        case 9: // id 9 is lokale 3
            return 3;
        case 5: // id 5 is lokale 2
            return 2;
        // case 2: // no longer exists
        //     return 4;
        case 12: // id 12 is lokale 5
            return 5;
        case 14: // id 14 is lokale 7
            return 7;
        case 13: // id 13 is lokale 6
            return 6;
        case 10: // id 10 is lokale 4
            return 4;
        case 16: // id 16 is lokale 8
            return 8;

        default:
            return 1;
    }
};

export const getOwnEvents = async (handleError, allEvents) => {
    // Get events of user from database
    const token = getToken();
    let url;
    // If user wants to see all events, not only the coming ones, then we set the date to the beginning of the website.
    if (allEvents) {
        url = `/api/v1/users/${getUserId()}/events?startdate=2021-09-01`;
    } else {
        url = `/api/v1/users/${getUserId()}/events?startdate=${moment(
            new Date()
        ).format('YYYY-MM-DD')}`;
    }

    const data = await getAndReturnDataWithAuth(url, token, handleError);

    if (data && data.userEvents) {
        // Set visibility if the user is on booking calendar, because they should be able to see their own bookings
        if (window.location.href.includes('booking-kalender')) {
            data.userEvents.forEach((event) => {
                event.isOwnEvent = true;
            });
        }
        return sortEvents(data.userEvents);
    }
};

export const returnRepeatSeqId = (ownEvents, selectedEventIdToDelete) => {
    const selectedEvent = ownEvents.filter(
        (event) => event.id === selectedEventIdToDelete
    );
    if (selectedEvent && selectedEvent[0] && selectedEvent[0].repeatSeqId) {
        return selectedEvent[0].repeatSeqId;
    }
};

export const getDateFromQuery = (self) => {
    const search = self.props.location.search;
    const date = new URLSearchParams(search).get('date');
    return date;
};

export const returnTotalPrices = async (arrayOfEventsIds, handleError) => {
    const data = await postAndReturnData(
        `/api/v1/users/${getUserId()}/events/calculate`,
        { arrayOfEventsIds },
        handleError
    );
    if (data && data.normalPrice) {
        return data;
    }
};

export const getListOfBookedMonths = (ownEvents) => {
    const bookedMonths = ownEvents.map((event) =>
        moment(event.startdate).format('MMMM')
    );

    let listOfMonthsUnique = [...new Set(bookedMonths)];

    // Check if the payment in the last month, would be later than the last event
    const lastDueDate = moment(new Date()).add(
        listOfMonthsUnique.length - 1,
        'months'
    );

    if (
        moment(lastDueDate).isAfter(
            moment(ownEvents[ownEvents.length - 1].startdate)
        )
    ) {
        // Remove the last month from the list of months
        listOfMonthsUnique.pop();
    }
    return listOfMonthsUnique;
};

// export const getListOfBookedMonthsDate = (ownEvents) => {
//   const bookedMonths = ownEvents.map((event) =>
//     moment(event.startdate).format("YYYY-MM-DD")
//   );
//   return [...new Set(bookedMonths)];
// };

export const returnTotalNormalPrice = async (arrayOfEventsIds, handleError) => {
    const data = await postAndReturnData(
        `/api/v1/users/${getUserId()}/events/calculate`,
        { arrayOfEventsIds },
        handleError
    );
    if (data && data.normalPrice) {
        return data.normalPrice;
    }
};

export const returnTotalDiscountPrice = async (
    arrayOfEventsIds,
    handleError
) => {
    const data = await postAndReturnData(
        `/api/v1/users/${getUserId()}/events/calculate`,
        { arrayOfEventsIds },
        handleError
    );

    if (data && data.normalPrice) {
        return data.totalPrice;
    }
};

export const getUserFromDb = async (userId, handleError, url) => {
    const token = getToken();
    let resource;
    if (url) {
        resource = url;
    } else {
        resource = `api/v1/users/${userId}`;
    }
    const userFromDb = await getAndReturnDataWithAuth(
        resource,
        token,
        handleError
    );

    return userFromDb;
};

export const getIsSubscriberUserFromDb = async (userId, handleError, url) => {
    const token = getToken();
    let resource;
    if (url) {
        resource = url;
    } else {
        resource = `api/v1/users/${userId}/is-subscriber`;
    }
    const isSubscriberFromDb = await getAndReturnDataWithAuth(
        resource,
        token,
        handleError
    );
    return isSubscriberFromDb;
};

export const getLastDayOfSubscription = () => {
    const dataFromLocalStorage = getLocalStorageJson(localStorageKey, {});
    return dataFromLocalStorage.currentUser.lastDayOfSubscription;
};
// If isFullProgressBar in context is true, it means that the user just signed up, so we should
// show the oplysninger step in the progressbar too.
export const getCurrentStage = () => {
    if (!getIsFullProgressBar()) {
        return 'auth';
    }
};

export const getOrderInfo = () => {
    const dataFromLocalStorage = getLocalStorageJson(localStorageKey, {});
    return dataFromLocalStorage.orderInfo;
};

export const returnOrderType = async (hasSubscription, draftOwnEvents) => {
    if (draftOwnEvents && draftOwnEvents.length > 0 && !hasSubscription) {
        return 'events';
    } else if (draftOwnEvents && draftOwnEvents.length < 1 && hasSubscription) {
        // User only buys subscription

        return 'subscription';
    } else if (draftOwnEvents && draftOwnEvents.length > 0 && hasSubscription) {
        // If user buys both events and subscription
        return 'combo';
    } else {
        return 'none';
    }
};

export const getSubscriptionOrderFromStorage = () => {
    const dataFromLocalStorage = getLocalStorageJson(localStorageKey, {});
    if (
        dataFromLocalStorage &&
        dataFromLocalStorage.order &&
        dataFromLocalStorage.order.subscription
    ) {
        return dataFromLocalStorage.order.subscription;
    }
};

export const getProfileImageFromStorage = () => {
    const dataFromLocalStorage = getLocalStorageJson(localStorageKey, {});
    if (dataFromLocalStorage && dataFromLocalStorage.imageUrl) {
        return dataFromLocalStorage.imageUrl;
    }
};

export const getLastRoomId = () => {
    const dataFromLocalStorage = getLocalStorageJson(localStorageKey, {});
    return dataFromLocalStorage.roomId;
};

export const hasPrevPageDestination = (props) => {
    if (
        props &&
        props.location &&
        props.location.state &&
        props.location.state.from
    ) {
        return true;
    }
};

export const getPrevPageDestination = (props) => {
    if (
        props &&
        props.location &&
        props.location.state &&
        props.location.state.from &&
        props.location.state.from.title
    ) {
        return props.location.state.from.title;
    }
};

export const getPrevPageUrl = (props) => {
    // Check if comingFromRoomNumber is in local storage, if it is, then it means that the user came from Location page
    const comingFromRoomNumber = getComingFromRoomNumber();
    if (comingFromRoomNumber) {
        return `/lokale/${getProperRoomId(comingFromRoomNumber)}`;
    } else if (
        props &&
        props.location &&
        props.location.state &&
        props.location.state.from &&
        props.location.state.from.path &&
        props.location.state.from.path !== ''
    ) {
        return props.location.state.from.path;
    }
};

export const getHaveSeenRenovationWarning = () => {
    const dataFromLocalStorage = getLocalStorageJson(localStorageKey, {});
    return dataFromLocalStorage.haveSeenRenovationWarning;
};

export const getPaymentParamsChecksum = async (
    orderId,
    type,
    amount,
    currency,
    description,
    productsBody,
    userId,
    isRenewCard
) => {
    const user = getUser();
    if (!user) {
        return { error: 'User not logged in' };
    }
    let params;
    let requestURL;
    if (isRenewCard) {
        requestURL = '/api/v1/payment/params-checksum?action=renew-card';
    } else {
        requestURL = '/api/v1/payment/params-checksum';
    }
    let requestBody = {
        userId,
        orderId,
        type,
        amount,
        currency,
        description,
        show3DSecure: 1,
        orders: productsBody
    };

    console.log('show requestbody', requestBody);
    try {
        const response = await fetchWithTimeout(requestURL, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            body: JSON.stringify(requestBody),
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${user.token}`,
                'Content-Type': 'application/json'
            },
            timeout: 10000
        });
        if (response.status === 200) {
            params = await response.json();
        } else {
            // If we have an error, due to that the user is not verified, we need to show an error message about that.
            const data = await response.json();
            if (data && data.message) {
                return { error: data.message };
            }
        }
    } catch (err) {
        // Timeouts if the request takes more than 10 seconds
        console.log(err);
        // const aborted = (err.name === 'AbortError');
    }
    return params;
};

export const getEmailVerifiedAt = () => {
    const dataFromLocalStorage = getLocalStorageJson(localStorageKey, {});
    return dataFromLocalStorage.currentUser.emailVerifiedAt;
};

export const getPhoneVerifiedAt = () => {
    const dataFromLocalStorage = getLocalStorageJson(localStorageKey, {});
    return dataFromLocalStorage.currentUser.phoneVerifiedAt;
};

export const getIsRenewCard = () => {
    const dataFromLocalStorage = getLocalStorageJson(localStorageKey, {});
    return dataFromLocalStorage.isRenewCard;
};

export const getComingFromRoomNumber = () => {
    const dataFromLocalStorage = getLocalStorageJson(localStorageKey, {});
    return dataFromLocalStorage.comingFromRoomNumber;
};
