import React from 'react';
import { Typography } from '@material-ui/core';
import styled from 'styled-components';
import ClickableLink from '../components/buttons/clickable-link/ClickableLink';
import { returnAuthenticated } from '../context/getData';

const UnderlinedText = styled.span`
    text-decoration: underline;
    cursor: pointer;
`;
const Answer = styled(Typography)`
    margin-bottom: 1.2rem;
    width: 80%;

    &.qa-colourful-question {
        color: ${(props) => props.theme.palette.colors.orange};
    }

    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        width: 100%;
    }
`;

const MobileSpace = styled.span`
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        display: block;
    }
`;

const QAData = [
    {
        id: 1,
        question: 'Værdier og vision',
        answers: (
            <Answer variant="body2">
                Visionen for Bülowsvej 10 - Den analoge oase, er at være en
                langsom, analog og tydelig ramme og vært for alle som har lyst
                til og finder det meningsfuldt at være her.
                <MobileSpace>
                    <br />
                </MobileSpace>
                Vi ønsker at være værter for mange forskellige mennesker og
                aktiviteter, men er ikke nødvendigvis det rigtige sted for alle
                mennesker og aktiviteter. 
                <MobileSpace>
                    <br />
                </MobileSpace>
                Vi stiller nogle krav til vores brugere og gæster.
            </Answer>
        )
    },
    {
        id: 2,
        question: 'Lokaleleje og annullering',
        answers: (
            <Answer variant="body2">
                Vi har pt. 8 lokaler til udlejning. Seks mindre fra 8 til 14 m2
                og tre større fra 34 til 43 m2. Det ene lokale kan ved særlige
                lejligheder udvides til 65 m2.
                <MobileSpace>
                    <br />
                </MobileSpace>
                Lokalerne kan f.eks. bruges til undervisning, møder, foredrag,
                bevægelse, øvning, kontor, terapi, massage, fest med mere. 
                <MobileSpace>
                    <br />
                </MobileSpace>
                Vi har 38 dB lyddøre i de fleste lokaler, men huset er generelt
                bedst egnet til akustiske aktiviteter.  <br />
                Dog kan der sagtens bruges stereoanlæg, så længe vinduerne
                holdes lukkede. <br />
                Al leje er back-to-back dvs. lejeren må ikke gå ind i lokalet
                før lejeperioden starter, og skal være helt ude når lejeperioden
                slutter. Minimumslejeperioden er 15 min. <br />
                <MobileSpace>
                    <br />
                </MobileSpace>
                Du kan altid annullere en lokalereservation, det koster mellem 0
                og 50 % af lokalelejen, alt efter hvornår der annulleres og om
                du har et musikermedlemskab eller ej:
                <br />
                Hvis du annullerer mere end én uge før lejetidspunktet, opkræves
                musikermedlemmer ingen leje, ikke medlemmer opkræves 25% af
                lejen.
                <br />
                Hvis du annullerer mindre end én uge før lejetidspunktet,
                opkræves musikermedlemmer 25% af lejen, ikke medlemmer opkræves
                50% af lejen.
            </Answer>
        )
    },
    {
        id: 3,
        question: 'Musikermedlemskab',
        answers: (
            <>
                <Answer variant="body2">
                    Vi har 3 typer musikermedlemskaber der kan være relevant for
                    dig der enten vil øve solo, med gruppe og/eller undervise i
                    musik.  <br />
                    <MobileSpace>
                        <br />
                    </MobileSpace>{' '}
                    Fælles for alle tre typer musikermedlemskaber er følgende:
                    <br />
                    <MobileSpace>
                        <br />
                    </MobileSpace>{' '}
                    <ul>
                        <li>
                            Alle tre musikermedlemskaber giver adgang til at
                            øve* på akustisk instrument eller sang i ledige
                            lokaler 24/7 i hele medlemsperioden.
                        </li>
                        <li>
                            Kalenderen på denne hjemmeside giver løbende
                            overblik over ledige og optagede lokaler.
                        </li>
                        <li>
                            Hvis der foregår undervisning i
                            lokalet, skal lokalet lejes.
                        </li>
                        <li>
                            Da vi har et begrænset antal medlemskaber, skal
                            medlemskabet godkendes af administrationen.
                        </li>
                        <li>Dette sker typisk inden for 2 - 3 hverdage.</li>
                        <li>
                            Hvis der skal bruges et lokale akut eller du ikke
                            får godkendt ansøgningen, kan lokalet lejes på
                            almindelige vilkår.
                        </li>
                        <li>
                            Medlemskabet starter ved godkendelsen. Hvis
                            godkendelsen falder midt i måneden, betales der kun
                            for de resterende dage i måneden.
                        </li>
                        <li>Derefter betales medlemsprisen månedlig forud.</li>
                        <li>
                            Musikermedlemskabet giver rabat på annullering af
                            lokaleleje.
                        </li>
                    </ul>
                    *Se muligheder og begrænsninger under de enkelte
                    medlemskaber.
                    <br />{' '}
                    <MobileSpace>
                        <br />
                    </MobileSpace>{' '}
                    <br />
                    <br />
                    <Typography variant="subtitle1">
                        Musikermedlemskab A:
                    </Typography>
                    <ul>
                        <li>
                            Giver adgang til både at øve solo eller med gruppe
                            24/7 i ledige lokaler.
                        </li>
                        <li>
                            For at få godkendt musikermedlemskab A, skal du have
                            en aktiv profil på{' '}
                            <UnderlinedText>
                                <a
                                    href="/opslagstavle"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    opslagstavlen
                                </a>
                            </UnderlinedText>{' '}
                            med billede og en kort beskrivelse af hvad du
                            underviser i.
                        </li>
                        <li>
                            Dette gælder uanset om du optager nye elever eller
                            ej eller kun øver med grupper. Dette bedes du også
                            tage med i beskrivelsen.
                        </li>
                        <li>
                            Medlemskab A giver 50% rabat på lokalelejen og leje
                            annullering.
                        </li>
                        <li>Medlemskab A koster 500 kr. per måned.</li>
                    </ul>
                    <hr />
                    <br />
                    <br />
                    <Typography variant="subtitle1">
                        Musikermedlemskab B:
                    </Typography>
                    <ul>
                        <li>
                            Giver adgang til at øve solo 24/7 i ledige lokaler.
                            Du er den eneste der er tilstede i lokalet hvis der
                            er flere end dig uanset årsag, skal lokalet lejes.
                        </li>
                        <li>
                            For at få godkendt musikermedlemskab B, skal du have
                            en aktiv profil på{' '}
                            <UnderlinedText>
                                {' '}
                                <a
                                    href="/opslagstavle"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    opslagstavlen
                                </a>
                            </UnderlinedText>{' '}
                            med billede og en kort beskrivelse af hvad du
                            underviser i.
                        </li>
                        <li>
                            Dette gælder uanset om du optager nye elever eller
                            ej, dette bedes du også tage med i beskrivelsen.
                        </li>
                        <li>Medlemskab B giver 50% rabat på lokalelejen.</li>
                        <li>Medlemskab B koster 400 kr. per måned.</li>
                    </ul>
                    <hr />
                    <br />
                    <br />
                    <Typography variant="subtitle1">
                        Musikermedlemskab C:
                    </Typography>
                    <ul>
                        <li>
                            Giver adgang til at øve solo 24/7 i ledige lokaler =
                            Du er den eneste der er tilstede i lokalet hvis der
                            er flere end dig uanset årsag, skal lokalet lejes.
                        </li>
                        <li>Medlemskab C koster 300 kr. per måned.</li>
                    </ul>
                </Answer>
            </>
        )
    },

    {
        id: 4,
        question: 'Kalender og opslagstavle',
        answers: (
            <Answer variant="body2">
                Opslagstavlen er en gratis ekstra service for alle brugerne af
                bulowsvej10.dk.
                <br />
                På opslagstavlen kan du oprette et opslag, hvor du præsenterer
                dig selv og hvad du tilbyder. Det kan være undervisning,
                behandling, terapi med mere.
                <br />
                <br />
                Det kræver kun et login at se og lave opslag på opslagstavlen. 
                For at få godkendt musikermedlemskab A og B, er det
                obligatorisk, at lave et opslag med tekst og billede på{' '}
                <UnderlinedText>
                    {' '}
                    <a
                        href="/opslagstavle"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        opslagstavlen
                    </a>
                </UnderlinedText>
                .
                <br />
                <br />
                Sådan gør du:
                <ol>
                    <li>
                        Giv dit opslag en kort og præcis titel (eks.
                        Guitarundervisning, bodySDS, samtaleterapi etc.) Beskriv
                        din undervisning / dit tilbud så præcist og beskrivende
                        som muligt. Der er plads til 700 tegn.
                    </li>
                    <li>
                        Tilføj kontaktoplysninger og eventuelt links til sociale
                        medier. Du vælger selv, hvordan du vil kontaktes, din
                        mail og telefonnummer er dog skjult for søgemaskiner, så
                        det kræver ekstra klik fra besøgende, at gøre det
                        synligt.
                    </li>
                    <li>
                        Upload op til 5 billeder af dig selv, dit instrument,
                        sessions etc. Vælg en eller flere kategorier for dit
                        opslag.
                    </li>
                </ol>
                NB! Den Lille Musikskole har intet ansvar for den undervisning,
                som brugerne af opslagstavlen tilbyder.
            </Answer>
        )
    },
    {
        id: 5,
        question: 'Årshjul',
        answers: (
            <Answer variant="body2">
                Lokalelejen er sæsonbetonet, det vil sige, der kan højest
                reserveres lokale en sæson ad gangen.  <br />
                En sæson løber fra 1. juli til 30. juni det følgende år. I
                januar måned reserverer hovedlejeren Den Lille Musikskole alle
                de tidsrum som det skønnes maksimalt skal bruges.  <br /> <br />
                I perioden 1. februar til 30. april frigives de ledige tider til
                alle medlemmer og nuværende lejere.  <br />
                Fra 1. maj frigives de resterende tider for alle.  <br /> <br />
                Hen over sommeren og i starten af efteråret frigives yderligere
                reservationer, musikskolen ikke skal bruge alligevel.
            </Answer>
        )
    },
    {
        id: 6,
        question: 'Fællesarealer',
        answers: (
            <Answer variant="body2">
                Indendørs består fællesarealerne af garderobe, toiletter, stue,
                skærmrum og gangarealet på første sal. Alle udendørs arealer
                betragtes også som fællesareal.
                <br />
                Eneste undtagelse er indhegningen omkring det lille japanske
                ahorn i haven, der er ikke adgang for gæster, hverken børn eller
                voksne.
                <br />
                <br />
                Fællesarealer kan frit benyttes af alle medlemmer og lejere
                24/7.
                <br />
                Dog kan gangarealet på første sal ikke bruges til at ophold sig
                eller opbevaring af genstande.
                <br />
                Skærmrummet i det gamle køkken, er det eneste sted på
                fællesarealerne hvor der må bruges computere og telefoner. I
                skærmrummet er der kun adgang for voksne over 18 år.
                <br />
                <br />
                Vi som værter, beder alle vores gæster sætte mobilen på lydløs,
                når de træder ind på matriklen. Døråbning og korte vigtige
                samtaler er muligt udendørs eller i skærmrummet.
                <br />
                Det er et analogt hus med mange børn, som vi prioriterer at give
                et fristed med nærværende voksne og børn. <br />
                Dette gælder alle gæster der kommer i huset.
                <br />
                Vi opfordrer også vores lejere til at undgå børn og voksne
                bruger skærme i de lejede lokaler.
            </Answer>
        )
    },

    // {
    //     id: 5,
    //     question: 'Lej lokale (ikke medlem)',
    //     answers: (
    //         <Answer variant="body2">
    //             <Typography variant="body2">
    //                 Lokale 1 og 2: 250/125 kr. i timen
    //                 <br />
    //                 Lokale 3: 200/100 kr. i timen <br />
    //                 Lokale 4 til 9: 60/30 kr. i timen
    //             </Typography>
    //             <br />
    //             Lej lokale{' '}
    //             <UnderlinedText>
    //                 <ClickableLink
    //                     pathname="/booking-kalender"
    //                     fromPath="/faq"
    //                     fromTitle="FAQ"
    //                     text="her."
    //                 />
    //             </UnderlinedText>
    //         </Answer>
    //     )
    // },
    // {
    //     id: 6,
    //     question: 'Lej lokale (medlem)',
    //     answers: (
    //         <Answer variant="body2">
    //             50% rabat på leje for medlemmer.  <br />
    //             Du skal være medlem i hele perioden fra reservation til og med
    //             lejeperioden.
    //         </Answer>
    //     )
    // },
    {
        id: 7,
        question: 'Husregler',
        answers: (
            <Answer variant="body2">
                <ul>
                    <li>
                        Alle, der færdes i huset, skal tage deres sko af i
                        garderoben eller bruge overtræk eller hjemmesko.
                    </li>
                    <li>
                        Alle udendørs og indendørs fællesarealer er skærmfri
                        områder. Undtagelsen er det særlige skærmrum, hvor der
                        kun er adgang for voksne over 18 år. Dette rum benyttes
                        også til lytning af podcast, lydbøger og lignende, samt
                        læsning i elektroniske bøger og brug af mobiltelefon
                        (som gerne må være kort og diskret). Vi henstiller til
                        at mobilen sættes på lydløs, når du træder ind på
                        matriklen. Og slet ikke bruger nogen enheder til surfing
                        og spil med mere. På denne måde er du som gæst med til
                        at opretholde værternes ønsker for vores sted.
                    </li>
                    <li>
                        Ingen gæster, elever, klienter, kunder må opholde sig i
                        udlejningslokaler uden medlemmet eller lejeren er til
                        stede.
                    </li>
                    <li>
                        Ingen gæster, elever, klienter, kunder må opholde sig i
                        andre udlejningslokaler end det lejede.
                    </li>
                    <li>
                        Al undervisningsmateriale, instrumenter, rekvisitter og
                        andet udstyr der befinder sig i udlejningslokalerne, som
                        ikke er nævnt i næste afsnit, må kun benyttes efter
                        direkte aftale med Den Lille Musikskole.
                    </li>
                    <li>De farvede taburetter må kun benyttes af børn.</li>
                    <li>
                        Støjende aktiviteter skal altid udøves for lukkede
                        vinduer og døre.
                    </li>
                    <li>Der skal luftes ud inden lokalet forlades.</li>
                    <li>
                        Klaverlåg lukkes ned og alle møbler, samt inventar
                        stilles pænt på plads.
                    </li>
                    <li>
                        Affald bæres ned i køkkenet til vores affaldssortering.
                    </li>
                    <li>Hele matriklen holdes røgfri.</li>
                    <li>
                        Udendørs ophold og aktiviteter må ikke være til gene for
                        naboerne.
                    </li>
                    <li>
                        Instrumenter, inventar m.m. må ikke fjernes fra huset
                        uden forudgående aftale med Den Lille Musikskole.
                    </li>
                    <li>
                        {' '}
                        Vi opfordrer til, men håndhæver ikke, at børn under 18
                        år heller ikke bruger skærme i udlejningslokalerne.
                    </li>
                </ul>
                <br />
            </Answer>
        )
    },

    {
        id: 8,
        question: 'Adgang til huset',
        answers: (
            <Answer variant="body2">
                Ved indgåelse af musikermedlemsskab tildeles adgang til huset
                24/7 i medlemsperioden. Lejere kan få adgang til huset 15 min.
                før lejeperioden starter, men ikke til det leje lokale før
                lejeperioden starter.
                <MobileSpace>
                    <br />
                </MobileSpace>
                Adgangen forgår via mobiltelefonen, og kræver du er logget ind.
                <br />
                Under dit navn i højre hjørne, kan du trykke en menu frem med en
                "Åben dør” funktion.
                <br />
                Det er ikke udlejerens ansvar, hvis medlemmet/lejeren ikke kan
                få adgang pga. manglende mobiltelefon.
                <br />
                <br />
                Adgang til huset sker pt. via bagdøren, alle elever og kunder
                lukkes ind af fordørens som pt. låses op manuelt med nøglen der
                sidder indvendigt.
                <br />
                <br />
                Døren har dørpumpe og lukker af sig selv. Det er ikke tilladt at
                holde døren åben med nogen genstande. Døren må kun åbnes og
                holdes fysisk åben af personer.
            </Answer>
        )
    },
    {
        id: 9,
        question: 'Print og kopi',
        answers: (
            <Answer variant="body2">
                {' '}
                Se teksten hen over printeren på første sal.
            </Answer>
        )
    },
    {
        id: 10,
        question: 'Beliggenhed, offentlig transport og parkering',
        answers: (
            <Answer variant="body2">
                Huset ligger tæt ved Landbohøjskolen (med den skønne have) og
                Gl. Kongevej. Henholdsvis 450 og 800 meter til metrostationerne:
                Frederiksberg Allé (M3) og Frederiksberg (M1, M2, M3). Busser i
                nærheden: 9A, 31, 26 og 71.
                <MobileSpace>
                    <br />
                </MobileSpace>
                Biler kan parkeres i gården mod betaling via parkeringsapp
                (Easypark, OK, ParkPark). Områdenummeret er 118.
                <MobileSpace>
                    <br />
                </MobileSpace>
                Der er automatisk kameraovervågning, som registrerer nummerplade
                ved ind- og udkørsel. Regler og vilkår fremgår af skiltningen på
                pladsen. <br />
                Udlejeren har intet ansvar for eventuelle afgifter ved manglende
                betaling for parkeringen.
                <MobileSpace>
                    <br />
                </MobileSpace>
                Medlemmer og lejere forpligter sig til at parkere cykler foran
                huset ud mod Bülowsvej, samt at sikre at eventuelle elever,
                deltagere, kunder, klienter og andre gæster gør det samme.
            </Answer>
        )
    },

    {
        id: 8,
        question: 'Annullering',
        answers: (
            <Answer variant="body2">
                Du kan altid annullere en lokalereservation, det vil dog koste
                dig mellem 0 og 50 % af lokalelejen, alt efter hvornår du
                annullerer og om du er medlem eller ej:
                <br />
                <br />
                Hvis du annullerer mere end én uge før lejetidspunktet, opkræves
                medlemmer ingen leje, ikke medlemmer opkræves 25% af lejen.
                <br />
                <br /> Hvis du annullerer mindre end én uge før lejetidspunktet,
                opkræves medlemmer 25% af lejen, ikke medlemmer opkræves 50% af
                lejen.
            </Answer>
        )
    }
];

export default QAData;
