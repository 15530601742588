import { Box, Typography } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { getAndReturnData } from '../../context/helperFunctions';
import ErrorDialog from '../dialogs/error-dialog/ErrorDialog';
import ImageDialog from '../dialogs/image-dialog/ImageDialog';
import RegularHeadline from '../headlines/RegularHeadline';
import RoomMapImage from '../../assets/rooms/plantegning.jpg';
import RegularButton from '../buttons/regular-button/RegularButton';
import { overridings } from '../../themes/DarkTheme';
import RoomDescriptionBulowsvej from './RoomDescriptionBulowsvej';
import { Context } from '../../context/Context';

const DescriptionText = styled(Typography)``;

const Underlined = styled.span`
    text-decoration: underline;
    cursor: pointer;
`;

const InfoContainer = styled(Box)`
    width: 60%;
    padding-right: 2rem;
    margin-bottom: 2rem;
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        width: 100%;
    }
`;

const ImageContainer = styled(Box)`
    width: 40%;
    @media (min-width: ${(props) => props.theme.breakpoints.values.md}px) {
        max-height: ${(props) => props.portrait && '350px'};
        overflow: ${(props) => props.portrait && 'hidden'};
        position: ${(props) => props.portrait && 'relative'};
    }

    cursor: -moz-zoom-in;
    cursor: -webkit-zoom-in;
    cursor: zoom-in;
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        width: 100%;
    }
`;

const FlexContainer = styled(Box)`
    display: flex;
    margin-bottom: 2rem;
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        display: block;
    }
`;

const RoomImage = styled.img`
    width: 100%;
    @media (min-width: ${(props) => props.theme.breakpoints.values.md}px) {
        position: ${(props) => props.portrait && 'absolute'};
        bottom: ${(props) => props.portrait && '-20%'};
    }
`;

// const LinkElm = styled.span``;

function RoomDescription(props) {
    const [roomName, setRoomName] = useState('');
    const [roomDescription, setRoomDescription] = useState('');
    const [roomImage, setRoomImage] = useState('');
    const [roomColor, setRoomColor] = useState('');
    const [roomArea, setRoomArea] = useState('');
    const [roomInventory, setRoomInventory] = useState('');
    const [useExamples, setUseExamples] = useState('');
    const [roomLocatedAt, setRoomLocatedAt] = useState('');
    // This should be used when we are implementing multiple pictures
    const [imageUrls, setImageUrls] = useState(['/images/avatar.png']);
    const [roomId, setRoomId] = useState(0);

    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const [imageDialogOpen, setImageDialogOpen] = useState(false);
    const [mapOpen, setMapOpen] = useState(false);

    const context = useContext(Context);

    const pricePerMeterNormal = 5; // 5 DKK per square meter
    const pricePerMeterDiscount = 2.5; // 2.5 DKK per square meter

    // Get selected room from url
    const getChosenRoom = () => {
        if (
            props &&
            props.match &&
            props.match.params &&
            props.match.params.id
        ) {
            return props.match.params.id;
        }
    };

    const handleError = () => {
        setErrorMessage('Noget gik galt. Prøv igen.');
        setError(true);
    };

    const getRoomInfo = async () => {
        const chosenRoom = getChosenRoom();
        if (!chosenRoom) {
            handleError();
        }

        setRoomId(Number(chosenRoom));

        console.log('show chosen room', chosenRoom);

        const roomData = await getAndReturnData(
            `/api/v1/room?locationId=1&roomId=${chosenRoom}`,
            handleError
        );
        if (!roomData || !roomData.rooms || roomData.rooms.length !== 1) {
            handleError();
            console.error('There are more rooms returned.');
        } else {
            setRoomName(roomData.rooms[0].name);
            setRoomDescription(roomData.rooms[0].description);
            // getRoomImage(chosenRoom);
            setRoomColor(roomData.rooms[0].colorRGB);
            setRoomArea(roomData.rooms[0].area);
            setRoomLocatedAt(roomData.rooms[0].locatedAt);
            setRoomInventory(roomData.rooms[0].inventory);
            getAndSetRoomImage(roomData);
            setUseExamples(roomData.rooms[0].useExamples);

            if (document.body.offsetWidth < 960) {
                setImageUrls(JSON.parse(roomData.rooms[0].mobileImageUrls));
            } else {
                setImageUrls(JSON.parse(roomData.rooms[0].imageUrls));
            }
        }
    };

    const getAndSetRoomImage = (roomData) => {
        if (document.body.offsetWidth < 960) {
            setRoomImage(roomData.rooms[0].imageUrlMobile);
        } else {
            setRoomImage(roomData.rooms[0].imageUrl);
        }
    };

    const calculatePrice = (pricePerArea, area) => {
        const result = area * pricePerArea;
        const stringResult = result.toString();
        // Instead of having a dot, display a comma if number is not an integer
        const stringWithComma = stringResult.replace(/\./g, ',');
        return stringWithComma;
    };

    const onImageClick = (imgUrl) => {
        // If the imgUrl is sent, it is coming from a carousel, so there are multiple images
        if (imgUrl) {
            setRoomImage(imgUrl);
        }
        setImageDialogOpen(true);
    };

    const onRoomLinkClick = (roomName) => {
        context.saveCalendarType('normal');
        const roomNumber = roomName.substr(roomName.length - 1);
        context.saveComingFromRoomPage(roomNumber);
        props.history.push(`/booking-kalender/${roomNumber}`);
    };

    // ComponentDidMount
    useEffect(() => {
        getRoomInfo();
        // eslint-disable-next-line
    }, [window.location.href]);

    const getNormalRoomPrice = (roomId) => {
        console.log('get room price');
        switch (roomId) {
            case 1:
                return 250;

            case 5:
                return 250; // lokale 2
            case 9: // lokale 3
                return 200;
            case 12:
                return 60;
            case 14:
                return 60;
            case 13:
                return 60;
            case 10:
                return 60;
            case 16:
                return 60;

            default:
                return '';
        }
    };

    const calculateMemberRoomPrice = (roomId) => {
        switch (roomId) {
            case 1: // lokale 1
                return 125;
            case 5:
                return 125; // lokale 2
            case 9: // lokale 3
                return 100;
            case 12:
                return 30;
            case 14:
                return 30;
            case 13:
                return 30;
            case 10:
                return 30;
            case 16:
                return 30;
            default:
                return '';
        }
    };
    return (
        <>
            {roomName === 'Bülowsvej 10' ? (
                <RoomDescriptionBulowsvej
                    onImageClick={onImageClick}
                    roomImage={roomImage}
                    imageUrls={imageUrls}
                />
            ) : (
                <div>
                    <RegularHeadline
                        title={roomName}
                        color={roomColor}
                        component="h2"
                    />
                    <FlexContainer>
                        <InfoContainer>
                            <Box mb="1rem">
                                <DescriptionText variant="body2">
                                    Størrelse: {roomArea} m2
                                </DescriptionText>{' '}
                            </Box>
                            <Box mb="1rem">
                                {' '}
                                <DescriptionText variant="body2">
                                    Medlemspris:{' '}
                                    {/* {calculatePrice(
                                        pricePerMeterDiscount,
                                        roomArea
                                    )}{' '} */}
                                    {calculateMemberRoomPrice(roomId)} kr.
                                </DescriptionText>
                            </Box>
                            <Box mb="1rem">
                                <DescriptionText variant="body2">
                                    Normalpris:{' '}
                                    {/* {calculatePrice(
                                        pricePerMeterNormal,
                                        roomArea
                                    )}{' '} */}
                                    {getNormalRoomPrice(roomId)} kr.
                                </DescriptionText>
                            </Box>
                            <Box mb="1rem">
                                <DescriptionText variant="body2">
                                    Inventar: {roomInventory}
                                </DescriptionText>
                            </Box>
                            <Box mb="1rem">
                                <DescriptionText variant="body2">
                                    Beskrivelse: {roomDescription}
                                </DescriptionText>
                            </Box>

                            <Box mb="1rem">
                                {' '}
                                <DescriptionText variant="body2">
                                    Placering: {roomLocatedAt}{' '}
                                    <Underlined
                                        onClick={() => setMapOpen(true)}
                                    >
                                        Se plantegning
                                    </Underlined>
                                </DescriptionText>
                            </Box>

                            <Box mb="1rem">
                                <DescriptionText variant="body2">
                                    Eksempler på anvendelsesmuligheder:{' '}
                                    {useExamples}
                                </DescriptionText>
                            </Box>

                            {/* <Box mb="1rem">
                        <DescriptionText variant="body2">
                            Du kan reservere dette lokale ved at trykke{' '}
                            <LinkElm onClick={() => onRoomLinkClick(roomName)}>
                                <Underlined>her</Underlined>
                            </LinkElm>
                            .
                        </DescriptionText>
                    </Box> */}
                            <Box width="170px">
                                <RegularButton
                                    title="Lej lokalet"
                                    onButtonClick={() =>
                                        onRoomLinkClick(roomName)
                                    }
                                    background={
                                        overridings.palette.colors.green
                                    }
                                />
                            </Box>
                        </InfoContainer>
                        <ImageContainer
                            portrait={roomName === 'Lokale 8' && true}
                            onClick={() => onImageClick()}
                        >
                            <RoomImage
                                portrait={roomName === 'Lokale 8' && true}
                                src={roomImage}
                                alt="room"
                            />
                        </ImageContainer>
                    </FlexContainer>
                </div>
            )}

            {imageDialogOpen && (
                <ImageDialog
                    url={roomImage}
                    close={() => setImageDialogOpen(false)}
                    altText="lokale"
                />
            )}

            {mapOpen && (
                <ImageDialog
                    component={RoomMapImage}
                    altText="lokaler tegning"
                    close={() => setMapOpen(false)}
                />
            )}

            {error && (
                <ErrorDialog
                    dialogTitle=""
                    close={() => {
                        setError(false);
                        setErrorMessage('');
                    }}
                    errorMessage={errorMessage}
                />
            )}
        </>
    );
}

export default withRouter(RoomDescription);
